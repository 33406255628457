import axios from "axios";
import qs from "qs";
import {
  getIdToken,
  getRefreshToken,
  handleLogin,
  logout,
} from "../lib/login-store";
import { refreshAuth } from "../../services/auth-api";
import { API_KEY, BASE_URL } from "../constants/env";

let isRefreshing = false;
let subscribers = [];

const client = axios.create({
  paramsSerializer: (params) => {
    let p = qs.stringify(params, {
      arrayFormat: "bracket",
      encode: false,
      allowDots: true,
    });
    return p;
  },
});

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRefreshed(token) {
  subscribers.map((cb) => cb(token));
}

client.interceptors.request.use(
  function (config) {
    if (config.url.startsWith(BASE_URL)) {
      if (!config._retry) {
        config.headers["Authorization"] = getIdToken();
        config.headers["x-api-key"] = API_KEY;
      }
    } else {
      delete config.headers.common["Authorization"];
      delete config.headers.common["x-api-key"];
      delete config.headers.common["x-p"];
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => response.data,
  (error) => {
    // console.error("Request Failed:", error.config);

    let responseStatus = error.response ? error.response.status : 0;
    const originalRequest = error.config;

    if (responseStatus === 401) {
      // console.log("Unauthorized Access, sending refresh token");
      if (!isRefreshing) {
        isRefreshing = true;
        refreshAuth(getRefreshToken)
          .then((response) => {
            // console.log("Got Tokens by Update Token");
            handleLogin(response);

            isRefreshing = false;
            onRefreshed(response.id_token);
            subscribers = [];
          })
          .catch((e) => {
            // console.log("REFRESH LOGOUT");
            logout();
            //Force user re-login when refresh token is invalid.
            // window.location = "/auth";
          });
      }
      const requestSubscribers = new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers.Authorization = token;
          originalRequest._retry = true;
          resolve(client(originalRequest));
        });
      });
      return requestSubscribers;
    } else if (responseStatus === 403) {
      // console.log("Unauthorized Access");

      //Force user re-login when refresh token is invalid.
      if (window.location.pathname !== "/acesso-negado") {
        window.location = "/acesso-negado";
      }
    } else if (responseStatus >= 400 /*&& responseStatus <= 500*/) {
      if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        // console.error("Status:", error.response.status);
        // console.error("Data:", error.response.data);
        // console.error("Headers:", error.response.headers);
      } else {
        // Something else happened while setting up the request
        // triggered the error
        // console.error("Error Message:", error.message);
      }
    } 
    // else {
    //   console.error("Ocorreu um erro ao processar sua solicitação");
    // }

    return Promise.reject(error);
  }
);

export default client;

import React, { useEffect, useState } from "react";
import InfoIcon from "../VectorIcons/InfoIcon";
import "./styles/EditStatusExternalProject.scss";
import Select from "react-select";
import DropdownIndicator from "../DropdownIndicator/DropdownIndicator";
import Textarea from "../Textarea/Textarea";
import Button from "../Button/Button";
import BoxFooter from "../BoxFooter/BoxFooter";

const EditStatusExternalProject = (props) => {
  const { handleChangeStatusExternalProject, id, getStatusExternalProject } =
    props;

  const [state, setState] = useState({
    projectId: id,
    status: undefined,
    justification: "",
  });

  let status = [
    { label: "Selecionado", id: 3 },
    { label: "Não Selecionado", id: 4 },
  ];

  const handleChangeStatus = (data) => {
    setState({
      ...state,
      status: data.id,
    });
  };

  const handleChangeDescription = (ev) => {
    setState({
      ...state,
      justification: ev.target.value,
    });
  };

  useEffect(() => {
    getStatusExternalProject(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div id="EditStatusExternalProject">
      <div className="contentEditStatusExternalProject">
        <div className="daysRemaining">
          <InfoIcon color="#ffa810" />
          Faltam <strong>25 dias</strong> para terminar o prazo de avalição
        </div>

        <form>
          <div className="formItem" style={{ zIndex: 200 }}>
            <label>STATUS DO PROJETO</label>
            <Select
              className="groupSelect"
              classNamePrefix="groupSelect"
              placeholder="Selecione"
              options={status}
              onChange={handleChangeStatus}
              // value={value}
              // defaultValue={defaultValue}
              components={{ DropdownIndicator }}
              isSearchable={false}
            />
          </div>
          <div className="formItem">
            {state.status === 4 && (
              <>
                <Textarea
                  maxRows={16}
                  label="JUSTIFICATIVA"
                  placeholder=""
                  handleChange={handleChangeDescription}
                  value={state.justification}
                />
                {/* {state.justification?.length < 255 ? (
                  <span className="charactersCounter">
                    {255 - state.justification?.length} caracteres restantes
                  </span>
                ) : (
                  <span className="charactersCounterError">
                    Limite de 255 caracteres atingido
                  </span>
                )} */}
              </>
            )}
          </div>

          <BoxFooter>
            <Button
              text="Alterar Status do Projeto"
              isValidForm={!!state.status}
              toValidate={true}
              classButton="buttonGreen large"
              dataQA="button-prospect-new-save"
              clickEvent={() => handleChangeStatusExternalProject()}
              disabled
            />
          </BoxFooter>
        </form>
      </div>
    </div>
  );
};

export default EditStatusExternalProject;

import React, { useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./views/Home/Home";
import Activities from "./views/Activities/Activities";
import NewActivity from "./views/Activities/NewActivity/NewActivity";
import ActivityEvaluation from "./views/Activities/ActivityEvaluation/ActivityEvaluation";
import Conflicts from "./views/Conflicts/Conflicts";
import RegisterOptions from "./views/RegisterOptions/RegisterOptions";
import Login from "./views/Login/Login";
import { RegisterProvider } from "./context/registerContext";
import ProjectSheet from "./views/ProjectSheet/ProjectSheet";
import { ProjectShettProvider } from "./context/sheetContext";
import NewSearch from "./views/NewSearch/NewSearch";
import { MapConflictProvider } from "./context/mapConflictContext";
import Logout from "./components/Auth/Logout";
import Unauthorized from "./components/Auth/Unauthorized/Unauthorized";
import Auth from "./components/Auth/Auth";
import Private from "./private";
import Research from "./views/Research/Research";
import { ActivitiesProvider } from "./context/activitiesContext";
import ThesaurusAdmin from "./admin/ThesaurusAdmin/ThesaurusAdmin";
import ListStatusActivities from "./views/ListStatusActivities/ListStatusActivities";
import GoogleAnalytics from "./services/GoogleAnalytics/GoogleAnalytics";
import LiteraryWork from "./views/LiteraryWork/LiteraryWork";
import ProjectSheetResearch from "./views/ProjectSheet/ProjectSheetResearch";
import RegisterDramaturgyAndVarieties from "./views/RegisterDramaturgyAndVarieties/RegisterDramaturgyAndVarieties";
import ProspectsAndProductions from "./views/ProspectsAndProductions/ProspectsAndProductions";
import { ProspectsAndProductionsProvider } from "./context/productionsAndProspectsContext";
import ReleasedTexts from "./views/ReleasedTexts/ReleasedTexts";
import UserContext from "./context/userContext";
import Redirecting from "./components/Redirecting/Redirecting";
import ProjectReleasedText from "./views/ReleasedTexts/ProjectReleasedText/ProjectReleasedText";
import ExternalProjects from "./views/ExternalProjects/ExternalProjects";

const Routes = () => {
  const userContext = useContext(UserContext);
  const isExternal =
    userContext.havePermission(["ExternalProject"]).length === 0;

  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <GoogleAnalytics>
          <Route path="/auth" exact component={Auth} />
          <Route path="/logout" exact component={Logout} />

          <Route path="/acesso-negado" exact component={Unauthorized} />

          <Route path="/login" exact render={(props) => <Login {...props} />} />

          <Route path="/projetos-externos" exact component={ExternalProjects} />

          <Route
            path="/admin/thesaurus"
            exact
            render={(props) => (
              <Private>
                <ThesaurusAdmin {...props} />
              </Private>
            )}
          />

          <ProspectsAndProductionsProvider>
            <Route
              path="/"
              exact
              render={(props) => (
                <Private>
                  {!userContext.me.loading &&
                  !userContext.monoPermission("TextRelease") &&
                  !isExternal ? (
                    <Home {...props} />
                  ) : isExternal ? (
                    <Redirect
                      to={{
                        pathname: "/projetos-externos",
                      }}
                    />
                  ) : (
                    <Redirecting />
                  )}
                </Private>
              )}
            />
          </ProspectsAndProductionsProvider>

          <Route
            path="/textos-liberados"
            exact
            render={(props) => (
              <Private>
                <ReleasedTexts {...props} />
              </Private>
            )}
          />

          <Route
            path="/texto-liberado/:id"
            exact
            render={(props) => (
              <Private>
                <ProjectReleasedText {...props} />
              </Private>
            )}
          />

          <Route
            path="/cadastrar"
            exact
            render={(props) => (
              <Private>
                <RegisterOptions {...props} />
              </Private>
            )}
          />

          <ActivitiesProvider>
            <Route
              path="/atividades"
              exact
              render={(props) => (
                <Private>
                  <Activities {...props} />
                </Private>
              )}
            />

            <Route
              path="/atividade/:type/:status"
              exact
              render={(props) => (
                <Private>
                  <ListStatusActivities {...props} />
                </Private>
              )}
            />
            <Route
              path="/atividades/:slug/nova-atividade"
              exact
              render={(props) => (
                <Private>
                  <NewActivity {...props} />
                </Private>
              )}
            />

            <Route
              path="/atividades/:type/:evaluation/:evaluationId"
              exact
              render={(props) => (
                <Private>
                  <ActivityEvaluation {...props} />
                </Private>
              )}
            />
          </ActivitiesProvider>

          <MapConflictProvider>
            <Route
              path="/mapa-conflitos"
              exact
              render={(props) => (
                <Private>
                  <Conflicts {...props} />
                </Private>
              )}
            />
          </MapConflictProvider>

          <ProspectsAndProductionsProvider>
            <Route
              path="/proponentes"
              exact
              render={(props) => (
                <Private>
                  <ProspectsAndProductions {...props} type="prospects" />
                </Private>
              )}
            />
            <RegisterProvider>
              <Route
                path="/pesquisa/cadastrar"
                exact
                render={(props) => (
                  <Private>
                    <Research {...props} />
                  </Private>
                )}
              />
              <Route
                path="/obra-literaria/cadastrar"
                exact
                render={(props) => (
                  <Private>
                    <LiteraryWork {...props} />
                  </Private>
                )}
              />
              <Route
                path="/cadastrar/:type"
                exact
                render={(props) => (
                  <Private>
                    <RegisterDramaturgyAndVarieties {...props} />
                  </Private>
                )}
              />
              <ProjectShettProvider>
                <Route
                  path="/ficha-projeto/:type/:id"
                  exact
                  render={(props) => (
                    <Private>
                      <ProjectSheet {...props} />
                    </Private>
                  )}
                />
              </ProjectShettProvider>

              <ProjectShettProvider>
                <Route
                  path="/ficha-pesquisa/:id"
                  exact
                  render={(props) => (
                    <Private>
                      <ProjectSheetResearch {...props} />
                    </Private>
                  )}
                />
              </ProjectShettProvider>
            </RegisterProvider>
            <Route
              path="/produtoras"
              exact
              render={(props) => (
                <Private>
                  <ProspectsAndProductions
                    {...props}
                    type="productioncompanies"
                  />
                </Private>
              )}
            />
            <ProjectShettProvider>
              <Route
                path="/busca"
                exact
                render={(props) => (
                  <Private>
                    <NewSearch {...props} />
                  </Private>
                )}
              />
            </ProjectShettProvider>
          </ProspectsAndProductionsProvider>
        </GoogleAnalytics>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;

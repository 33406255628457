import React, { useContext } from "react";
import FileView from "../../../../components/VectorIcons/FileView";
import EditIcon from "../../../../components/VectorIcons/EditIcon";
import DownloadIcon from "../../../../components/VectorIcons/DownloadIcon";
import AppContext from "../../../../context/appContext";
import RegisterContext from "../../../../context/registerContext";
import ReleaseTextIcon from "../../../../components/VectorIcons/ReleaseTextIcon";
import ReleaseTextIconMarked from "../../../../components/VectorIcons/ReleaseTextIconMarked";
import DeleteIcons from "./DeleteIcons/DeleteIcons";

const BlockList = (props) => {
  const {
    item,
    list,
    condition,
    toggleGroupList,
    havePermission,
    handleEditFile,
    _class,
    handleConfirmationDeleteFile,
    handleOpenReleaseTexts,
    returnColor,
    type,
    isExternal,
    haveExternalPermission,
  } = props;

  const appContext = useContext(AppContext);
  const registerContext = useContext(RegisterContext);
  const isMainAndHasVersions =
    _class === "mainItem" && !!list && list.length > 1;

  const canDelete = item?.activityCount === 0 && !item.release;

  return (
    <div className={`listItem ${_class}`}>
      <span className="fileType">
        {item?.attachmentType?.name}{" "}
        {item?.attachmentType?.name === condition && item.chapterNumber}
        {item?.attachmentType?.name === condition && (
          <span className="blockInfo">Bloco {item.blockNumber}</span>
        )}
      </span>

      {!haveExternalPermission && (
        <span
          className={`lightText fileVersion ${
            isMainAndHasVersions ? "hasItems" : ""
          }`}
          onClick={(ev) => isMainAndHasVersions && toggleGroupList(ev)}
        >
          {item?.version} ª<span className="versionLabel">versão</span>
          {isMainAndHasVersions && <div className="versionArrow" />}
        </span>
      )}

      <span className="lightText fileName"> {item?.fileName} </span>
      <span className="lightText receivedAt">
        {" "}
        {appContext.convertDatePtBr(item?.receivedAt)}{" "}
      </span>

      <div className="actions">
        {item?.fileName?.slice(-3) !== "fdx" && item?.fileName !== null && (
          <span
            className="actionItem"
            onClick={() => {
              appContext.setCurrentFileUrl(item?.fileId, item?.fileName);
              appContext.setCurrentStepBox("ViewerFileBox");
            }}
          >
            {" "}
            <FileView />{" "}
          </span>
        )}
        {havePermission && (
          <span className="actionItem" onClick={() => handleEditFile(item)}>
            {" "}
            <EditIcon />{" "}
          </span>
        )}
        <span
          className="actionItem download"
          onClick={() => registerContext.getUrlS3(item?.fileId, item?.fileName)}
        >
          {" "}
          <DownloadIcon />{" "}
        </span>
        {type !== "literaryworks" && !isExternal && (
          <span
            className="actionItem"
            onClick={() => handleOpenReleaseTexts(item, list)}
          >
            {!!item?.release ? <ReleaseTextIconMarked /> : <ReleaseTextIcon />}
          </span>
        )}
        {havePermission && (
          <span
            className={`actionItem ${!canDelete ? "blockDelete" : ""}`}
            onClick={() => canDelete && handleConfirmationDeleteFile(item)}
          >
            <DeleteIcons
              release={item?.release}
              activityCount={item?.activityCount}
              returnColor={returnColor}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default BlockList;

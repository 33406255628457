import React, { useContext, useEffect, useState } from "react";
import "./styles/ProjectSheet.scss";
import { calculateDifferenceInDays } from "../../shared/utils/calculateDifferenceInDays";
import AppContext from "../../context/appContext";
import ProjectSheetContext from "../../context/sheetContext";
import UserContext from "../../context/userContext";
import CloudIcon from "../../components/VectorIcons/CloudIcon";
import EditIcon from "../../components/VectorIcons/EditIcon";
import InfoPaperBasic from "./InfoPaper/InfoPaperBasic/InfoPaperBasic";
import InfoPaperComplementary from "./InfoPaper/InfoPaperComplementary/InfoPaperComplementary";
import InfoPaperRelevance from "./InfoPaper/InfoPaperRelevance/InfoPaperRelevance";
import InfoCard from "../../components/InfoCard/InfoCard";
import TableLoading from "../../components/TableLoading/TableLoading";
import RelatedContentCard from "../../components/RelatedContentCard/RelatedContentCard";
import ScriptBoxCaller from "../../components/ScriptBoxCaller/ScriptBoxCaller";
import TelevisionIcon from "../../components/VectorIcons/TelevisionIcon";
import SimpleButton from "../../components/SimpleButton/SimpleButton";
import CharactersTable from "./CharactersTable/CharactersTable";
import SeasonsCarousel from "./SeasonsCarousel/SeasonsCarousel";
import LoadError from "../../components/LoadError/LoadError";
import ActivitiesCard from "../../components/ActivitiesCard/ActivitiesCard";
import PhasesCard from "../../components/PhasesCard/PhasesCard";
import ViewFile from "../../components/ViewFile/ViewFile";
import RecommendationCard from "./RecommendationCard/RecommendationCard";
import ContentAttachments from "./ContentAttachments/ContentAttachments";
import ContentHeader from "./ContentHeader/ContentHeader";
import LightBoxes from "./LightBoxes/LightBoxes";
import ChaptersAndEpisodes from "../ChaptersAndEpisodes/ChaptersAndEpisodes";
import { sendEvent } from "../../services/GoogleAnalytics/methods";
import { handleChapterOrEpisode } from "../../shared/utils/handleChapterOrEpisode";
import { handleDramaturgyTypeByFormat } from "../../shared/utils/handleDramaturgyTypeByFormat";
import TagStatus from "../../components/TagStatus/TagStatus";
import { getService, putService } from "../../services/script-api";
import InfoPaperExternalProject from "./InfoPaper/InfoPaperExternalProject/InfoPaperExternalProject";
import ListVideos from "./ListVideos/ListVideos";
import InfoCards from "./InfoCards/InfoCards";
import Financing from "./Financing/Financing";
import ProducerInfo from "./ProducerInfo/ProducerInfo";
import ScriptBox from "../../components/ScriptBox/ScriptBox";
import TableListVideos from "./ListVideos/TableListVideos";
import impressoraIcon from "../../assets/icons/impressora.png";
import ExternalPDF from "./Documents/ExternalPDF";

const isValidPlot = (list) => Object.values(list.plot).some((e) => !!e.length);

const isValidThematics = (list) =>
  Object.values(list.thematic).some((e) => !!e.length);

const isValidUniverses = (list) =>
  Object.values(list.universe).some((e) => !!e.length);

const isValidRelevance = (list) => {
  return isValidPlot(list) || isValidThematics(list) || isValidUniverses(list);
};

const validDramaturgiesComplementary = (list) => {
  return (
    !!list?.contentOrigns.length ||
    !!list?.narratives.length ||
    !!list?.genres.length ||
    !!list?.subGenres.length ||
    !!list?.spaces.length ||
    !!list?.periods.length ||
    !!list?.epochs.length ||
    !!list?.storyLine
  );
};

const validVarietiesComplementary = (list) => {
  return (
    !!list.genres.length ||
    !!list.subGenres.length ||
    !!list.tags.length ||
    !!list.logLine
  );
};

const validLiteraryComplementary = (list) => {
  return !!list.genres.length || !!list.universe.length || !!list.storyLine;
};

const ProjectSheet = (props) => {
  const appContext = useContext(AppContext);
  const sheetContext = useContext(ProjectSheetContext);
  const userContext = useContext(UserContext);

  const [stateExternalProject, setStateExternalProject] = useState({});
  const [stateCurrentTab, setStateCurrentTab] = useState("projectSheet");
  const [pdfOpen, setPdfOpen] = useState(false);
  const [owner, setOwner] = useState();
  const close = () => setPdfOpen(!pdfOpen);

  let compareTypes = {
    dramaturgia: {
      endPoint: "dramaturgies",
      permission: "Dramaturgies",
      isValidComplementary: validDramaturgiesComplementary,
      chaptersOrEpisodes: "Capítulo/Episódio",
      activityEvaluation: "dramaturgia",
    },
    variedades: {
      endPoint: "varieties",
      permission: "Varieties",
      isValidComplementary: validVarietiesComplementary,
      chaptersOrEpisodes: "Episódio",
      activityEvaluation: "variedades",
    },
    "obra-literaria": {
      endPoint: "literaryworks",
      permission: "LiteraryWorks",
      isValidComplementary: validLiteraryComplementary,
      chaptersOrEpisodes: "",
      activityEvaluation: "obra-literaria",
    },
  };

  useEffect(() => {
    let body = document.querySelector("body");
    body.scrollTo(0, 0);
    sheetContext._getProjectSheet(
      props.match.params.id,
      compareTypes[props.match.params.type].endPoint
    );

    return () => {
      sheetContext.clearSheet();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  useEffect(() => {
    appContext.getTypeThesaurus(
      "1",
      compareTypes[props.match.params.type].endPoint
    );
    appContext.getTypeThesaurus(
      "21",
      compareTypes[props.match.params.type].endPoint
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const compileChannels = () => {
    const compiledChannels = [];
    const channels = sheetContext.list.result.channel;
    channels.forEach((channel) => {
      if (!channel.content.parentId) {
        compiledChannels.push({
          parentName: channel.content.name,
          parentId: channel.content.parentId,
          description: channel.descriptions[0],
          children: channels.filter(
            (item) => item.content.parentId === channel.content.id
          ),
        });
      }
    });
    return compiledChannels;
  };

  const list = sheetContext.list.result;
  const isExternal = !!list?.projectStatus && !!list?.creationMode;

  const chapterOrEpisode = handleChapterOrEpisode(
    handleDramaturgyTypeByFormat(list?.format?.name)
  )

  useEffect(() => {
    if (!!list?.productionCompanies?.[0]?.id && isExternal) {
      (async () => {
        const data = await getService(
          `productioncompanies/${list?.productionCompanies?.[0]?.id}/owner`
        );
        setOwner(data?.data);
      })();
    }
  }, [list, isExternal]);

  if (sheetContext.list.loading) {
    return <TableLoading />;
  }

  // Permissão página externa
  // const haveExternalPermission =
  //   userContext.havePermission(["ExternalProject"]).length === 0;

  if (list === undefined) return <LoadError />;

  const compileDataId = (data) => {
    let i = [];
    if (!!data) {
      data.forEach((el) => i.push(el.id));
    }
    return i;
  };

  const returnDays = (days) => (days + 1 > 0 ? `${days + 1} dias` : "-");

  const compileContentOriginIds = () => {
    let content = [];

    if (list.contentOrigns.length > 0) {
      list.contentOrigns.forEach((el) => {
        content.push({
          contentOriginId: el.content.id,
          contentOriginDescriptions: el.descriptions,
        });
      });
    }
    return content;
  };

  let dataProject = {};
  let basicDataDramaturgiesAndVarieties = {};

  if (
    compareTypes[props.match.params.type].endPoint === "dramaturgies" ||
    compareTypes[props.match.params.type].endPoint === "varieties"
  ) {
    basicDataDramaturgiesAndVarieties = {
      id: props.match.params.id,
      receivedAt: list.receivedAt,
      creators: sheetContext.compileCreators(list.creators),
      notCompileCreators: list.creators,
      chaptersOrEpisodes: list.chaptersOrEpisodes,
      formatId: list.format.id,
      origins: list.origins,
      channel: list.channel,
      seasonNumber: list.seasonNumber,
      notCompileProductionCompanies: list.productionCompanies,
      productionCompanies: sheetContext.compileProduct(
        list.productionCompanies
      ),
      title: list.title,
    };
  }

  if (compareTypes[props.match.params.type].endPoint === "dramaturgies") {
    dataProject = {
      basic: {
        ...basicDataDramaturgiesAndVarieties,
        soapOperaSchedule: !!list.soapOperaSchedule
          ? list.soapOperaSchedule.id
          : null,
      },
      complementaries: {
        genresIds: compileDataId(list.genres),
        subGenresIds: compileDataId(list.subGenres),
        spacesIds: compileDataId(list.spaces),
        contentOrigns: !!list.contentOrigns ? compileContentOriginIds() : null,
        narratives: compileDataId(list.narratives),
        periods: compileDataId(list.periods),
        epochIds: compileDataId(list.epochs),
        storyLine: list.storyLine,
      },
      relevance: {
        plot: {
          primariesIds: compileDataId(list.plot.primaries),
          secundariesIds: compileDataId(list.plot.secundaries),
          tertiaryIds: compileDataId(list.plot.tertiaryIds),
        },
        thematic: {
          primariesIds: compileDataId(list.thematic.primaries),
          secundariesIds: compileDataId(list.thematic.secundaries),
          tertiaryIds: compileDataId(list.thematic.tertiaryIds),
        },
        universe: {
          primariesIds: compileDataId(list.universe.primaries),
          secundariesIds: compileDataId(list.universe.secundaries),
          tertiaryIds: compileDataId(list.universe.tertiaries),
        },
      },
    };
  }

  if (compareTypes[props.match.params.type].endPoint === "varieties") {
    dataProject = {
      basic: {
        ...basicDataDramaturgiesAndVarieties,
        programFrequency: list.programFrequency?.id,
      },
      complementaries: {
        genresIds: compileDataId(list.genres),
        subGenresIds: compileDataId(list.subGenres),
        logLine: list.logLine,
        tags: list.tags,
      },
    };
  }

  if (compareTypes[props.match.params.type].endPoint === "literaryworks") {
    dataProject = {
      basic: {
        id: props.id,
        authors: list.authors.map((e) => e.value || e.id),
        publishers: list.publishers.map((e) => e.value || e.id),
        publicDomain: list.publicDomain || false,
        receivedAt: list.receivedAt,
        suggestedBy:
          list.suggestedBy.map((item) => ({
            ...item,
            label: item.name,
            suggesterId: item.id,
            value: item.id,
          })) || [],
        title: list.title || "",
        formatId: list.format?.id || "",
        publicationYear: list.publicationYear || "",
      },
      complementaries: {
        genresIds: compileDataId(list.genres),
        universeIds: compileDataId(list.universe),
        storyLine: list.storyLine,
      },
    };
  }

  const getStatusExternalProject = (data) => setStateExternalProject(data);

  const isValidCost = () =>
    list?.costAndFinancialInformation?.publicNoteCost > 0 &&
    list?.costAndFinancialInformation?.ownResourcesCost > 0 &&
    list?.costAndFinancialInformation?.nationalCoProducerCost > 0 &&
    list?.costAndFinancialInformation?.internationalCoProducerCost > 0 &&
    list?.costAndFinancialInformation?.costPerEpisode > 0 &&
    list?.costAndFinancialInformation?.totalCost > 0;

  const isYoutube = (url) => {
    if (!!url) {
      let p =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      return p.test(url);
    }
  };

  const handleChangeStatusExternalProject = () => {
    putService(`externalproject/status`, stateExternalProject)
      .then((res) => {
        appContext.sendMessage("Projeto alterado com sucesso!");
        sheetContext._getProjectSheet(
          props.match.params.id,
          compareTypes[props.match.params.type].endPoint
        );
        appContext.setCurrentStepBox("closed");
        sendEvent("Projeto externo", "Alterou status");
      })
      .catch((e) => {
        appContext.sendMessage("Erro ao alterar o projeto", "error");
        appContext.setCurrentStepBox("closed");
      });
  };

  return (
    <div id="Project">
      <ContentHeader
        list={list}
        isExternal={isExternal}
        stateCurrentTab={stateCurrentTab}
        setStateCurrentTab={setStateCurrentTab}
        chapterOrEpisode={chapterOrEpisode}
      />
      {list.projectType === "Dramaturgia" && !isExternal && (
        <>
          <div
            style={{
              display: stateCurrentTab === "attachments" ? "unset" : "none",
            }}
          >
            <div className={`${chapterOrEpisode === 'Capítulos' ? 'mainContainer'  : 'mainContainerExtended'} `}>
              {stateCurrentTab === "attachments" && (
                <ChaptersAndEpisodes
                  contentId={list.id}
                  chapterOrEpisode={chapterOrEpisode}
                />
              )}
            </div>
          </div>
        </>
      )}
      <div
        style={{
          display: stateCurrentTab === "producerInfo" ? "unset" : "none",
        }}
      >
        <ProducerInfo list={list} owner={owner} />
      </div>
      <div
        style={{
          display: stateCurrentTab === "projectSheet" ? "unset" : "none",
        }}
      >
        <div className="mainContainer">
          <div className="infoContainer">
            {isExternal && (
              <>
                <div className="infoPaper infoPaperStatus">
                  <div className="paper">
                    <div className="paperTitle">
                      <span>Status do projeto</span>
                      <TagStatus
                        id={list.projectStatus.id}
                        name={list.projectStatus.description}
                      />
                      {list.projectStatus.id === 2 && (
                        <ScriptBoxCaller
                          callerIcon={<EditIcon />}
                          event={() =>
                            appContext.setCurrentStepBox("editExternalProject")
                          }
                        />
                      )}
                    </div>
                  </div>
                  {list.projectStatus.id === 2 && (
                    <div className="infoField">
                      <span className="infoName">PRAZO PARA AVALIAÇÃO</span>
                      <span className="children">
                        {returnDays(
                          calculateDifferenceInDays(
                            list?.channels?.[0]?.deadline
                          )
                        )}
                      </span>
                    </div>
                  )}
                  {!!list.channels?.[0]?.justification &&
                    list.projectStatus.id === 4 && (
                      <div className="infoField">
                        <span className="infoName">Justificativa</span>
                        <span className="children">
                          {list.channels?.[0]?.justification}
                        </span>
                      </div>
                    )}
                </div>
                <InfoPaperExternalProject list={list} />
              </>
            )}
            {!isExternal && (
              <>
                <InfoPaperBasic
                  paperTitle="Dados Básicos"
                  data={list}
                  contentOrigns={list.contentOrigns}
                  callerIcon={<EditIcon />}
                  callerEvent={() =>
                    appContext.setCurrentStepBox("editBasicData")
                  }
                  havePermission={
                    userContext.havePermission([
                      compareTypes[props.match.params.type].permission,
                    ]).length === 0
                  }
                  receivedAt={appContext.formatDate(
                    appContext.convertDatePtBr(list.receivedAt)
                  )}
                  channels={
                    props.match.params.type !== "obra-literaria" &&
                    compileChannels()
                  }
                />

                <InfoPaperComplementary
                  paperTitle="Dados Complementares"
                  data={list}
                  callerIcon={<EditIcon />}
                  contentOrigns={list.contentOrigns}
                  type={compareTypes[props.match.params.type]}
                  callerEvent={() =>
                    appContext.setCurrentStepBox("editComplementaryData")
                  }
                  havePermission={
                    userContext.havePermission([
                      compareTypes[props.match.params.type].permission,
                    ]).length === 0
                  }
                  isValid={compareTypes[
                    props.match.params.type
                  ].isValidComplementary(list)}
                />

                {compareTypes[props.match.params.type].endPoint ===
                  "dramaturgies" && (
                  <InfoPaperRelevance
                    callerEvent={() =>
                      appContext.setCurrentStepBox(
                        "editRelevanceComplementaryData"
                      )
                    }
                    callerIcon={<EditIcon />}
                    havePermission={
                      userContext.havePermission([
                        compareTypes[props.match.params.type].permission,
                      ]).length === 0
                    }
                    paperTitle="Dados Complementares por Relevância"
                    isValid={
                      compareTypes[props.match.params.type].endPoint ===
                      "dramaturgies"
                        ? isValidRelevance(list)
                        : () => {}
                    }
                    plots={list.plot}
                    thematics={list.thematic}
                    universes={list.universe}
                  />
                )}
              </>
            )}
          </div>

          <div className="cardsContainer">
            {isExternal && (
              <InfoCards
                title="Gerar Relatório em PDF"
                pdf
                callerIcon={
                  <div className="impressoraIcon">
                    <img src={impressoraIcon} alt="Abrir PDF" />
                  </div>
                }
                callerEvent={() => setPdfOpen(true)}
              />
            )}
            <InfoCard
              callerEvent={[
                () => appContext.setCurrentStepBox("uploadAttachment"),
                () => appContext.setCurrentStepBox("editAttachment"),
              ]}
              callerIcon={<CloudIcon />}
              cardTitle="Anexos"
              files={list.attachments.filter(
                (file) =>
                  !!file.fileId &&
                  file?.attachmentType?.name !== "Capítulo/Episódio"
              )}
              havePermission={
                userContext.havePermission([
                  compareTypes[props.match.params.type].permission,
                ]).length === 0
              }
              condition={
                compareTypes[props.match.params.type].chaptersOrEpisodes
              }
              isExternal={isExternal}
            />

            <ContentAttachments
              data={list.attachments}
              idProject={props.match.params.id}
              type={compareTypes[props.match.params.type].endPoint}
              projectType={list.projectType}
              condition={
                compareTypes[props.match.params.type].chaptersOrEpisodes
              }
              reload={() =>
                sheetContext._getProjectSheet(
                  props.match.params.id,
                  compareTypes[props.match.params.type].endPoint
                )
              }
              havePermission={
                userContext.havePermission([
                  compareTypes[props.match.params.type].permission,
                ]).length === 0
              }
              isExternal={isExternal}
            />

            <ActivitiesCard
              cardTitle={!isExternal ? "Atividades recentes" : "Atividades"}
              contentType={
                compareTypes[props.match.params.type].activityEvaluation
              }
              projectId={props.match.params.id}
            />

            {isExternal && (
              <>
                <InfoCards
                  handleEvent={() => appContext.setCurrentStepBox("viewVideos")}
                  countData={list.videos.length}
                  hasBaseboard={true}
                  viewAll="Ver todos os vídeos"
                  title="Videos"
                >
                  {!!list.videos.length &&
                    list.videos.slice(0, 3).map((video, i) => {
                      return (
                        <React.Fragment key={i}>
                          <ListVideos
                            url={video.url}
                            key={i}
                            title={video.title}
                            password={video.password}
                            subTitle={video?.type?.description}
                            platform={
                              !!video.url && !!isYoutube(video.url)
                                ? "Youtube"
                                : "Vimeo"
                            }
                          />
                        </React.Fragment>
                      );
                    })}

                  {!list.videos.length && (
                    <div className="cardContent">
                      <span>Não há nenhum video.</span>
                    </div>
                  )}
                </InfoCards>
                <InfoCards title="Custos e Financiamentos">
                  <div className="contentCardsFinancing">
                    {isValidCost() && (
                      <React.Fragment>
                        {list?.costAndFinancialInformation?.publicNoteCost >
                          0 && (
                          <Financing
                            label="EDITAIS PÚBLICOS"
                            value={
                              list?.costAndFinancialInformation?.publicNoteCost
                            }
                          />
                        )}

                        {list?.costAndFinancialInformation?.ownResourcesCost >
                          0 && (
                          <Financing
                            label="RECURSOS PRÓPRIOS"
                            value={
                              list?.costAndFinancialInformation
                                ?.ownResourcesCost
                            }
                          />
                        )}

                        {list?.costAndFinancialInformation
                          ?.nationalCoProducerCost > 0 && (
                          <Financing
                            label="CONDUTORES NACIONAIS"
                            value={
                              list?.costAndFinancialInformation
                                ?.nationalCoProducerCost
                            }
                          />
                        )}

                        {list?.costAndFinancialInformation
                          ?.internationalCoProducerCost > 0 && (
                          <Financing
                            label="CONDUTORES INTERNACIONAIS"
                            value={
                              list?.costAndFinancialInformation
                                ?.internationalCoProducerCost
                            }
                          />
                        )}

                        {list?.costAndFinancialInformation
                          ?.cultureIncentiveLawCost > 0 && (
                          <Financing
                            label="LEIS DE INCENTIVO A CULTURA"
                            value={
                              list?.costAndFinancialInformation
                                ?.cultureIncentiveLawCost
                            }
                          />
                        )}

                        {!!list?.costAndFinancialInformation?.others?.key &&
                          !!list?.costAndFinancialInformation?.others
                            ?.value && (
                            <Financing
                              label={
                                list?.costAndFinancialInformation?.others?.key
                              }
                              value={
                                list?.costAndFinancialInformation?.others?.value
                              }
                            />
                          )}

                        <div className="borderTop" />
                      </React.Fragment>
                    )}

                    {list?.costAndFinancialInformation?.costPerEpisode > 0 && (
                      <Financing
                        label="PROPOSTAS DE ESTIMATIVA DE CUSTO POR EPISÓDIO"
                        value={
                          list?.costAndFinancialInformation?.costPerEpisode
                        }
                      />
                    )}

                    {list?.costAndFinancialInformation?.totalCost > 0 && (
                      <Financing
                        label="PROPOSTAS DE ESTIMATIVA DE CUSTO TOTAL"
                        value={list?.costAndFinancialInformation?.totalCost}
                      />
                    )}
                  </div>
                </InfoCards>
              </>
            )}

            {compareTypes[props.match.params.type].endPoint !==
              "literaryworks" &&
              !isExternal && (
                <PhasesCard
                  cardTitle="Fases do projeto"
                  projectId={props.match.params.id}
                  type={compareTypes[props.match.params.type].endPoint}
                />
              )}
            {!isExternal && (
              <RecommendationCard
                cardTitle={`Recomendação ${
                  compareTypes[props.match.params.type].endPoint ===
                  "literaryworks"
                    ? "da Obra"
                    : "do projeto"
                }`}
                callerEvent={() =>
                  appContext.setCurrentStepBox("recomendation")
                }
                recommendation={sheetContext.list.result?.recommendation}
              />
            )}
          </div>
        </div>

        {compareTypes[props.match.params.type].endPoint !== "literaryworks" &&
          !isExternal && (
            <div className="relatedContentContainer">
              <div className="contentHeader">
                <span className="contentTitle">Conteúdo relacionado</span>

                {userContext.havePermission([
                  compareTypes[props.match.params.type].permission,
                ]).length === 0 && (
                  <ScriptBoxCaller
                    callerIcon={<EditIcon />}
                    event={() =>
                      appContext.setCurrentStepBox("editRelatedContent")
                    }
                  />
                )}
              </div>

              <div className="contentCardsContainer">
                {list.associations.map((association, index) => {
                  return (
                    <RelatedContentCard
                      key={index}
                      id={association.contentId}
                      contentType={association.contentType}
                      cardTitle={
                        !!association.type ? association.type.name : ""
                      }
                      productName={association.title}
                      format={association.format}
                      soapOperaSchedule={association.soapOperaSchedule}
                      editable={association.editable}
                    />
                  );
                })}
              </div>
            </div>
          )}

        {compareTypes[props.match.params.type].endPoint === "dramaturgies" &&
          !isExternal && (
            <div className="charactersContainer">
              <div className="charactersHeader">
                <span className="contentTitle">Personagens</span>

                {userContext.havePermission([
                  compareTypes[props.match.params.type].permission,
                ]).length === 0 && (
                  <SimpleButton
                    text="Novo Personagem"
                    classButton="buttonBlue small"
                    dataQA="button-prospect-edit"
                    onBreadcrumb={true}
                  >
                    <ScriptBoxCaller
                      callerIcon={<TelevisionIcon color="#FFF" />}
                      event={() =>
                        appContext.setCurrentStepBox("createCharacter")
                      }
                    />
                  </SimpleButton>
                )}
              </div>

              <div className="charactersListContainer">
                <div className="charactersList">
                  <CharactersTable
                    id={props.match.params.id}
                    havePermission={
                      userContext.havePermission([
                        compareTypes[props.match.params.type].permission,
                      ]).length === 0
                    }
                  />
                </div>
              </div>
            </div>
          )}

        <SeasonsCarousel
          id={list.id}
          productId={list.productId}
          title={list.title}
          type={props.match.params.type}
        />
        {appContext.current.step === "ViewerFileBox" && (
          <ViewFile url={appContext.file?.url} title={appContext.file?.title} />
        )}
        {appContext.current.step === "viewVideos" && (
          <ScriptBox
            open={appContext.current.step === "viewVideos"}
            removePadding={true}
            title="Videos"
            size="medium"
          >
            <TableListVideos videos={list.videos} />
          </ScriptBox>
        )}
        {isExternal && (
          <ExternalPDF
            open={pdfOpen}
            close={close}
            data={list}
            returnDays={returnDays}
            owner={owner}
          />
        )}
        <LightBoxes
          id={props.match.params.id}
          type={compareTypes[props.match.params.type]}
          data={list}
          dataProject={dataProject}
          getStatusExternalProject={getStatusExternalProject}
          handleChangeStatusExternalProject={handleChangeStatusExternalProject}
          dataStatusProject={list?.projectStatus}
        />
      </div>
    </div>
  );
};

export default ProjectSheet;

import React, { useContext, useState } from "react";
import "./styles/VersionsListChapter.scss";
import DownloadIcon from "../../../../components/VectorIcons/DownloadIcon";
import EditIcon from "../../../../components/VectorIcons/EditIcon";
import ReleaseTextIcon from "../../../../components/VectorIcons/ReleaseTextIcon";
import ReleaseTextIconMarked from "../../../../components/VectorIcons/ReleaseTextIconMarked";
import RegisterContext from "../../../../context/registerContext";
import ConfirmationBox from "../../../../components/ConfirmationBox/ConfirmationBox";
import { deleteService } from "../../../../services/script-api";
import AppContext from "../../../../context/appContext";
import DeleteIcons from "../../../ProjectSheet/ListFiles/BlockList/DeleteIcons/DeleteIcons";

const VersionsListChapter = (props) => {
  const {
    attachments,
    handleDate,
    handleEditFile,
    handleOpenReleaseTexts,
    getPlanning,
    isLastTableItem,
  } = props;
  const registerContext = useContext(RegisterContext);
  const appContext = useContext(AppContext);

  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const [isFile, setFile] = useState();

  const handleConfirmationBox = (file) => {
    setFile(file);
    setOpenConfirmationBox(true);
  };

  const closedBox = () => {
    setOpenConfirmationBox(false);
  };

  const handleDeleteFile = (file) => {
    appContext.setShowGlassLoading(true);

    deleteService("contents/attachments", file.id)
      .then(() => {
        appContext.sendMessage(`Arquivo ${file.fileName} deletado com sucesso`);
        setOpenConfirmationBox(false);
        getPlanning();
        appContext.setShowGlassLoading(false);
      })
      .catch((error) => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(`Erro ao deletar arquivo`, "error");
      });
  };

  const returnColor = (count) => (!!count ? "#7f808e" : "#ec1045");

  return (
    <div className="versionsListChapterContainer">
      <div className="versionsListChapterHeader">
        <span className="version">Versões</span>
        <span className="file">Arquivo</span>
        <span className="delivery">Entregue</span>
        <span className="released">Liberado</span>
      </div>

      <ul className="versionsListChapterItems">
        {attachments.map((item, index) => {
          return (
            <li className="versionItemChapter" key={index}>
              <span className="versionChapter">{`${item.version}ª`}</span>
              <span className="fileChapter">{item.fileName}</span>
              <span className="deliveryChapter">{handleDate(item.receivedAt)}</span>
              <span className="releasedChapter">{handleDate(item.releaseDate)}</span>
              <div className="actionsChapter">
                <div onClick={() => handleEditFile(item)}>
                  <EditIcon />
                </div>
                <span
                  onClick={() =>
                    registerContext.getUrlS3(item.fileId, item.fileName)
                  }
                >
                  <DownloadIcon />
                </span>
                <div
                  onClick={() =>
                    handleOpenReleaseTexts(
                      item,
                      attachments.filter(
                        (attachment) => attachment.fileId !== item.filedId
                      )
                    )
                  }
                >
                  {item.release ? (
                    <ReleaseTextIconMarked />
                  ) : (
                    <ReleaseTextIcon />
                  )}
                </div>
                {(attachments.length > 1 || isLastTableItem) && (
                  <span
                    onClick={() =>
                      !item.release &&
                      !item.activityCount &&
                      handleConfirmationBox(item)
                    }
                  >
                    <DeleteIcons
                      release={item.release}
                      activityCount={item.activityCount}
                      returnColor={returnColor}
                    />
                  </span>
                )}
              </div>
            </li>
          );
        })}
      </ul>

      {openConfirmationBox && (
        <ConfirmationBox
          open={openConfirmationBox}
          textButton="Sim, excluir"
          closedBox={closedBox}
          handleEvent={() => handleDeleteFile(isFile)}
          title="Tem certeza?"
          text="Este anexo será excluído permanentemente do sistema e essa ação não poderá ser desfeita"
        />
      )}
    </div>
  );
};

export default VersionsListChapter;

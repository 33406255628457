import jwt from "jsonwebtoken";

function setAccessToken(accessToken) {
  window.localStorage.setItem("accesstoken", accessToken);
}
function getAccessToken() {
  return window.localStorage.getItem("accesstoken");
}

function setIdToken(idToken) {
  window.localStorage.setItem("idtoken", idToken);
}
function getIdToken() {
  return window.localStorage.getItem("idtoken");
}

function setRefreshToken(refreshToken) {
  window.localStorage.setItem("refreshtoken", refreshToken);
}
const getRefreshToken = () => {
  return window.localStorage.getItem("refreshtoken");
};

function wrappeUser() {
  if (getIdToken() == null || getAccessToken() == null) return null;

  let _idToken = jwt.decode(getIdToken());
  let _accessToken = jwt.decode(getAccessToken());

  if (_accessToken == null || _idToken == null) return null;

  let email = _accessToken.email
    .replace("adcognitoprovider_", "")
    .replace("cognito_", "")
    .replace("Cognito-login_", "");

  const user = {
    name: _idToken.given_name,
    login: _idToken["samaccountname"],
    email,
  };

  return user;
}

const handleLogin = (data) => {
  if (!data) {
    removeToken();
    return;
  }

  if (data["accessToken"]) setAccessToken(data["accessToken"]);

  if (data["idToken"]) setIdToken(data["idToken"]);

  if (data["refreshToken"]) setRefreshToken(data["refreshToken"]);
};

function removeToken() {
  window.localStorage.removeItem("accesstoken");
  window.localStorage.removeItem("idtoken");
  window.localStorage.removeItem("refreshtoken");
  window.localStorage.removeItem("loggedUser");
}

function logout() {
  window.localStorage.clear();
}

const current = wrappeUser;

export {
  wrappeUser as default,
  current,
  getRefreshToken,
  getIdToken,
  getAccessToken,
  handleLogin,
  logout,
};

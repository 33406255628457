import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./styles/Activities.scss";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ActivitiesContext from "../../context/activitiesContext";
import UserContext from "../../context/userContext";
import AppContext from "../../context/appContext";
import ActivitiesNavigation from "./ActivitiesNavigation/ActivitiesNavigation";
import CreateNewActivity from "./CreateNewActivity/CreateNewActivity";
import ActivityNotifications from "./ActivityNotifications/ActivityNotifications";
import { getService } from "../../services/script-api";
import TableLoading from "../../components/TableLoading/TableLoading";
import HeaderList from "./ActivityNotifications/HeaderList";
import ActivitiesListItem from "./ActivitiesListItem/ActivitiesListItem";
import EditActivity from "./EditActivity/EditActivity";

const Activities = () => {
  const activitiesContext = useContext(ActivitiesContext);
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);

  const [stateActivities, setStateActivities] = useState({
    loading: true,
    list: [],
    activities: [],
  });
  const [stateUsers, setStateUsers] = useState({ loading: true, users: [] });
  const [stateCurrentActivity, setStateCurrentActivity] = useState([]);
  const [stateFilter, setStateFilter] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [stateGroup, setStateGroup] = useState({ loading: true, groups: [] });
  const [stateCountActivities, setStateCountActivities] = useState({
    loading: true,
    count: 0,
  });
  const [internalLoading, setInternalLoading] = useState(false);

  const getActivities = (groupId) => {
    setLoadingActivities();
    setInternalLoading(true);
    getService(`activities/groups/${groupId}`).then((activities) => {
      setStateActivities({
        ...stateActivities,
        list: activities.data,
        loading: false,
      });
      setInternalLoading(false);
    });
  };


  const setLoadingActivities = () => {
    setStateActivities({
      ...stateActivities,
      loading: true,
    });
  };

  const makeGroupNotStarted = (activities) => activities.filter((e) => !e.user);
  const filterStatusGroup = (list, status) =>
    list.filter(
      (e) =>
        parseInt(e.status?.id) === parseInt(status) &&
        e.group.value === activitiesContext.currentType
    );

  const groupByUser = (activities, users) => {
    let list = {};
    users.forEach((user) => {
      list[user.name] = activities.filter(
        (e) =>
          !!e.user &&
          e.user.name === user.name &&
          parseInt(e.status?.id) !== 4 &&
          parseInt(e.status?.id) !== 5 &&
          parseInt(e.status?.id) !== 6
      );
    });
    return list;
  };

  const groupByActivities = (activities, listUsers) => {
    let groupNotStarted = { "A direcionar": makeGroupNotStarted(activities) };
    let toRevision = { "A revisar": filterStatusGroup(activities, 5) };
    let users = groupByUser(activities, listUsers);
    let allGroups = [];

    allGroups.push(groupNotStarted, toRevision, users);

    setStateActivities({
      ...stateActivities,
      loading: false,
      activities: allGroups,
    });
  };

  const makeActiveUsers = (users) => {
    let a = [];
    users.forEach((user) => {
      user.isActive = true;
      a.push(user);
    });
    return a;
  };

  const monthUsers = (users) => {
    setStateUsers({
      ...stateUsers,
      loading: false,
      users: makeActiveUsers(users),
    });
  };

  const filterCurrentType = () =>
    stateGroup.groups.filter(
      (e) => e.value === activitiesContext.currentType
    )[0];

  const getUsers = () => {
    getService("users/analysts", {
      ActivityGroups: userContext.me.user.activityGroups,
    }).then((users) => monthUsers(users.data));
  };

  const setLoadingActivitiesAcount = () => {
    setStateCountActivities({
      ...stateCountActivities,
      loading: true,
    });
  };

  const getActivitiesCount = () => {
    setLoadingActivitiesAcount();
    let objCount = {
      group: filterCurrentType(activitiesContext.currentType)?.id || 1,
    };
    getService("activities/feedback/count", objCount).then((res) => {
      setStateCountActivities({
        ...stateCountActivities,
        loading: false,
        count: res.data,
      });
    });
  };

  const setCurrentType = (type) => {
    activitiesContext.setCurrentType(type);

    const selectedGroup = stateGroup.groups?.find((group) => group.value === type);
    if (selectedGroup) {
      getActivities(selectedGroup.id);
    }
  };


  const verifyGroup = (name) =>
    name !== "A direcionar" &&
    name !== "Feedbacks Pendentes" &&
    name !== "A Revisar" &&
    name !== "Cancelados";
  const returnStyle = (name) =>
    verifyGroup(name)
      ? { backgroundColor: appContext.generateColorFromName(name) }
      : { color: "black" };

  const toggleOpenList = (ev) => {
    let targetActive = ev.target.closest(".activitySection");

    if (targetActive) {
      targetActive.classList.toggle("closed");
    }
  };

  const editableActivity = (activity) => {
    setStateCurrentActivity(activity);
    appContext.setCurrentStepBox("editProjectActivity");
  };

  const editableFeedback = (activity) => {
    setStateCurrentActivity(activity);
    appContext.setCurrentStepBox("editProjectFeedback");
  };

  const getGroup = () => {
    getService("activities/groups").then((group) => {
      setStateGroup({
        ...stateGroup,
        loading: false,
        groups: group.data,
      });
    });
  };

  const toggleUser = (userName) => {
    let newState = [...stateFilter];

    if (newState.includes(userName)) {
      setStateFilter(newState.filter((e) => e !== userName));
    } else {
      newState.push(userName);
      setStateFilter(newState);
    }
  };

  useEffect(() => {
    getGroup()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!activitiesContext.currentType) {
      const selectedGroup = stateGroup?.groups?.find(
        (group) => group.value === activitiesContext.currentType
      );
      if (selectedGroup) {
        getActivities(selectedGroup.id);
      }
    }
  }, [stateGroup.groups]);


  useEffect(() => {
    if (!!stateGroup.groups.length) getActivitiesCount();
    // eslint-disable-next-line
  }, [activitiesContext.currentType, stateGroup.groups]);

  useEffect(() => {
    if (!userContext.me.loading) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.me.loading]);

  useEffect(() => {
    if (!!stateActivities.list.length && !stateUsers.loading) {
      groupByActivities(
        stateActivities.list.filter(
          (e) => e.group.value === activitiesContext.currentType
        ),
        stateUsers.users
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitiesContext.currentType, stateActivities.list, stateUsers.loading]);

  const toggleAll = (options) => setStateFilter(options);

  const getUser = (name) => stateUsers.users.filter((e) => e.name === name);

  const _getActivity = () => getActivities();

  if (stateActivities.loading)
    return (
      <div className="container">
        <TableLoading />
      </div>
    );

  return (
    <div id="Activities">
      <Helmet>
        <title>Script - Atividades</title>
      </Helmet>
      <Breadcrumb>
        <h2> Atividades Recentes </h2>
      </Breadcrumb>

      <ActivitiesNavigation
        groups={stateGroup.groups}
        loading={stateGroup.loading}
        currentType={activitiesContext.currentType}
        setCurrentType={setCurrentType}
      />

      <div className="container">
        <div className="activitiesTopSection">
          <CreateNewActivity currentType={activitiesContext.currentType} />
        </div>

        <ActivityNotifications
          loading={stateCountActivities.loading}
          countActivities={stateCountActivities.count}
        />

        <div className="activitiesTable">
          <HeaderList
            analysts={stateUsers.users.filter((e) =>
              e.groups.includes(
                filterCurrentType(activitiesContext.currentType)?.id
              )
            )}
            toggleUser={toggleUser}
            toggleAll={toggleAll}
            setCurrentType={setCurrentType}
            checkAll={checkAll}
            setCheckAll={setCheckAll}
          />

          {
            internalLoading ?
              <div className="container">
                <TableLoading />
              </div>
              :
              !!stateActivities.activities &&
              stateActivities.activities.map((activity, index) => {
                return (
                  <ul key={index}>
                    {Object.entries(activity)
                      .filter((e) => !stateFilter.includes(e[0]))
                      .map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            {(getUser(item[0]).length === 0 ||
                              getUser(item[0])[0]?.groups.includes(
                                filterCurrentType(activitiesContext.currentType)
                                  ?.id
                              )) && (
                                <li className="activitySection closed">
                                  <div
                                    className="activitySectionHeader"
                                    onClick={(ev) => toggleOpenList(ev)}
                                    style={returnStyle(item[0])}
                                  >
                                    <div className="itemArrow" />
                                    <span
                                      className={`sectionName ${item[0] === "Feedbacks Pendentes"
                                        ? "feedbackSection"
                                        : ""
                                        }`}
                                    >
                                      {item[0]} {!!item[0]} (
                                      {
                                        item[1].filter(
                                          (e) =>
                                            e.group.value ===
                                            activitiesContext.currentType
                                        ).length
                                      }
                                      )
                                    </span>
                                  </div>

                                  <div className="activitiesList">
                                    <ActivitiesListItem
                                      item={item[1]}
                                      i={index}
                                      data={stateActivities}
                                      bgColor={returnStyle(item[0])}
                                      handleEditActivity={editableActivity}
                                      handleEditFeedback={editableFeedback}
                                      name={item[0]}
                                    />
                                  </div>
                                </li>
                              )}
                          </React.Fragment>
                        );
                      })}
                  </ul>
                );
              })
          }
        </div>
      </div>

      {appContext.current.step === "editProjectActivity" && (
        <EditActivity
          currentActivity={stateCurrentActivity}
          type={stateCurrentActivity.contentType}
          _getActvity={_getActivity}
          open={appContext.current.step === "editProjectActivity"}
        />
      )}
    </div>
  );
};

export default Activities;
import React, { useContext, useState, useCallback, useEffect } from "react";
import "./Navigation.scss";
import Masks from "../../../components/VectorIcons/Masks";
import Microphone from "../../../components/VectorIcons/Microphone";
import ChannelContext from "../../../context/externalProjectsContext";
import { returnFictionAndNotFictionLabel } from "../../../shared/utils/returnFictionAndNotFictionLabel";

const Navigation = (props) => {
  const { pagingInfo } = props;

  const [stateCurrentTypeId, setCurrentTypeId] = useState(
    pagingInfo[0]?.type || ""
  );
  const channelContext = useContext(ChannelContext);

  const setFilter = (type) => {
    channelContext.setCurrentTypeSearchType(type);
    setCurrentTypeId(type);
  };

  const updateFilter = useCallback(() => {
    channelContext.setFilter({
      pageNumber: 1,
      Limit: 12,
    });
  }, [channelContext]);

  const returnTypeIcon = (tabType) => {
    const types = {
      Todos: <></>,
      Dramaturgia: (
        <Masks
          color={stateCurrentTypeId === "Dramaturgia" ? "#ffffff" : "#adafef"}
        />
      ),
      Variedades: (
        <Microphone
          color={stateCurrentTypeId === "Variedades" ? "#ffffff" : "#adafef"}
        />
      ),
    };
    return types[tabType];
  };

  useEffect(() => {
    let done = false;
    if (done) updateFilter();
    return () => {
      done = true;
    };
  }, [updateFilter]);

  useEffect(() => {
    setCurrentTypeId(pagingInfo[0]?.type || "");
  }, [pagingInfo]);

  return (
    <div id="Navigation">
      <div className="container">
        {pagingInfo.map(({ type, pagingInfo: pagination }) => {
          return (
            <div
              key={type}
              className={`itemNavigation ${
                stateCurrentTypeId === type ? "active" : ""
              }`}
              onClick={() => setFilter(type)}
            >
              {returnTypeIcon(type)}
              <span>{returnFictionAndNotFictionLabel(type)}</span>
              <span className="counter">
                {pagination?.totalItems ? ` (${pagination?.totalItems})` : ""}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Navigation;
